<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Products <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'products.new' }" :disabled="$cannot('product.create')">
          New Product
        </b-button>
      </b-col>
    </b-row>

    <!-- search -->
    <display-results :index-name="productsIndex" v-slot:default="{ items }">
      <b-table
        :fields="fields"
        :items="items"
        show-empty
        stacked="sm"
        @sort-changed="reSort"
        bordered
        no-local-sorting
        hover
      >
        <template v-slot:cell(id)="{ item }">
          <b-link :to="{ name: 'products.edit', params: { id: item.id } }">
            {{ item.id }}
          </b-link>
        </template>

        <template v-slot:cell(name)="{ item }">
          <b-link :href="productPageLink(item)" target="_blank">{{ item.name }}</b-link>
        </template>

        <template v-slot:cell(status)="{ item }">
          <b-badge :variant="`${item.main_status ? 'primary' : 'secondary'}`">
            {{ item.main_status ? 'Active' : 'Inactive' }}
          </b-badge>
        </template>

        <template v-slot:cell(rating)="{ item }">
          <b-form-rating
            v-model="item.rating"
            v-if="item.rating > 0"
            :variant="ratingColor(item.rating)"
            precision="2"
            size="sm"
            inline
            no-border
            show-value
          />
          <b-row v-else>
            <b-col cols="auto" class="pl-3">
              <b-badge>No rating</b-badge>
            </b-col>
          </b-row>
        </template>

        <template v-slot:cell(options)="{ item }">
          <b-dropdown text="More" variant="light" size="sm">
            <b-dropdown-item :to="{ name: 'products.edit', params: { id: item.id } }">
              Edit
            </b-dropdown-item>

            <b-dropdown-item :href="productPageLink(item)" target="_blank">
              View Product
            </b-dropdown-item>

            <b-dropdown-item
              :to="{
                name: 'products.edit.reviews',
                params: { id: item.id },
                query: { tab: 'pending' },
              }"
            >
              Reviews
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </display-results>
  </div>
</template>

<script>
import DisplayResults from '../../components/DisplayResults.vue'

export default {
  name: 'ProductsPage',
  inject: ['$cannot'],
  components: { DisplayResults },
  data() {
    return {
      productsIndex: process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', sortable: false },
        { key: 'main_category', sortable: false, label: 'Category' },
        { key: 'cheapest_price', sortable: false, label: 'Price', formatter: price => price.amount / 100 },
        { key: 'rating' },
        { key: 'status', sortable: false },
        { key: 'options', label: '' },
      ]
    },
  },
  methods: {
    ratingColor(rating) {
      if (rating > 4) return 'success'
      return rating > 3 ? 'warning' : 'danger'
    },
    productPageLink(product) {
      return `${process.env.VUE_APP_APP_URL}${product.slug}`
    },
    reSort(context) {
      // :busy.sync="isBusy"
      console.log('🚀 ~ file: ProductsPage.vue ~ line 106 ~ reSort ~ context', context)
    },
  },
}
</script>
