import algoliasearch from 'algoliasearch/lite'
import dayjs from 'dayjs'
import * as Cookies from 'js-cookie'

export const getValidationState = ({ dirty, validated, valid = null }: Record<string, boolean | null>) => {
  return dirty || validated ? valid : null
}

interface SearchRequestParams {
  facets?: string
  highlightPostTag: string
  highlightPreTag: string
  page: number
  query: string
  tagFilters: string
}

interface SearchRequest {
  indexName: string
  params: SearchRequestParams
}

export const configureSearchClient = (id: string, key: string, host?: string) => {
  const algoliaClient = algoliasearch(id, key)
  const customClient = {
    search(requests: Array<SearchRequest>) {
      return fetch(host as string, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') as string,
        },
        mode: 'cors',
        credentials: 'include',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          requests,
          page: requests.map(req => req.params.page)[0] + 1,
        }),
      }).then(res => res.json())
    },
  }

  return host ? customClient : algoliaClient
}

export const formatDate = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

export const adjustExpirationDate = (date: string) => {
  return dayjs(date).add(1, 'second')
}

export const formatDateOrdered = (date: string) => {
    const dateWithoutTimezone = new Date(date).toISOString().slice(0, -1);
    return dayjs(dateWithoutTimezone).format('DD.MM.YYYY HH:mm:ss');
}

type MoneyObject = { amount: string; currency: string }
export const formatMoney = (money: MoneyObject) => {
  const fMoney = money as MoneyObject
  const formatter = new Intl.NumberFormat('nb-NO', { style: 'currency', currency: fMoney.currency })

  return formatter.format(Number(fMoney.amount) / 100)
}
